<template>
  <div class="row">
    <div class="col-md-12 text-dark">
      <div class="card">
        <div class="card-body">
          <h2>products.</h2>
          <p v-if="loading">there is no item.</p>
          <p v-else>there are {{ items.length }} items.</p>
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>NAME</th>
                  <th>STOCK</th>
                  <th>PRICE (Rp)</th>
                  <th>CATEGORY</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <span v-if="item.stocks > 0">{{ item.stocks }}</span>
                    <span v-else class="text-danger">sold</span>
                  </td>
                  <td>Rp{{ item.price }}</td>
                  <td>{{ item.category.nama }}</td>
                </tr>
                <tr v-if="loading">
                  <td colspan="5" class="text-center"><em>wait a second...</em></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: '',
      loading: true,
    }
  },

  mounted() {
    this.getProducts()
  },

  methods: {
    async getProducts() {
      let { data, error } = await this.$supabase
        .from('products')
        .select(`
          id, name, stocks, price,
          category(nama)
        `)
        .order('created_at', { ascending: false})
      
      if(data) {
        this.items = this.filterStock(data)
        this.loading = false
      }
      
      if(error) console.error(error)
    },

    filterStock(data) {
      let ready = []
      let sold = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].stocks > 0) {
          ready.push(data[i])
        } else {
          sold.push(data[i])
        }
      }
      return ready.concat(sold)
    }
  }
}
</script>
